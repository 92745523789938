@use '/src/assets/styles/variables';
@import "~@ng-select/ng-select/themes/default.theme.css";

.button {
    background: none;
    border-style: solid;
    border-width: 1px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11pt;
    height: 3em;

    color: variables.$hedgeserv-blue;
    border-color: #6DCFF6;

    &:hover:enabled {
        background-color: variables.$hedgeserv-blue;
        border-color: #FFFFFF;
        color: #FFFFFF;
    }

    transition: all .1s ease-in-out;

    &:hover:not(:disabled) {
        color: white;
        background-color: variables.$hedgeserv-blue;
    }

    &:disabled {
        cursor: not-allowed;
        color: variables.$color-disabled;
        border-color: variables.$color-disabled;
    }
}

.link-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: variables.$hedgeserv-blue;
}

.link-button:disabled {
    cursor: not-allowed;
    color: variables.$color-disabled;
}

.link-button.fading {
    color: variables.$color-lightgray;
    transition: color ease-in-out .1s;

    &:hover {
        color: variables.$hedgeserv-blue;
    }
}

form {
    margin-bottom: 3em;
}

form .input-field {
    display: block;
    width: 100%;
    height: 3em;
    padding-left: 1em;

    color: #000000;

    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
    border-color: #D1D3D4;
    background-color: #F8F8F9;

    outline: none;
    margin-bottom: 1em;
    font-weight: bold;

    &:focus {
        border-color: variables.$hedgeserv-blue;
    }

    &::placeholder {
        font-weight: normal;
        font-style: italic;
    }

    &:focus::placeholder {
        color: transparent;
    }
}

form label {
    line-height: 1.6em;
    text-transform: uppercase;
    color: variables.$color-gray;
}

form .ng-select {
    outline: none;
    margin-bottom: 1em;
}

form .ng-select {
    &.ng-select-single {
        height: 3em;

        &.ng-select-opened {
            .ng-select-container {
                color: #000000;
                border-color: #D1D3D4;
                background-color: #F8F8F9;
            }
        }

        .ng-select-container {
            width: 100%;
            height: 3em;
            padding-left: 1em;

            border-style: solid;
            border-width: 1px;
            border-radius: 2px;

            color: #000000;
            border-color: #D1D3D4;
            background-color: #F8F8F9;

            .ng-value-container {
                padding-left: 0;
                font-weight: bold;

                .ng-placeholder {
                    font-weight: normal;
                }
            }
        }

        .ng-dropdown-panel.ng-select-bottom {
            top: 110%;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items {
            .ng-option {
                &.ng-option-marked {
                    background-color: #E7F6FD;

                    .ng-option-label {
                        color: variables.$hedgeserv-blue;
                    }
                }

                &.ng-option-selected {
                    &:not(.ng-option-marked) {
                        background-color: inherit;
                    }

                    .ng-option-label {
                        font-weight: normal;
                    }
                }

                .ng-option-label {
                    color: #808285;
                }
            }
        }
    }
}

.type-select-wrapper label {
    font-size: 1rem;
    font-weight: 700;
    font-family: NewsGothicBTDemi;
    display: block;
    margin-top: 0.054545rem;
    margin-bottom: 0.054545rem;
}

.type-select-wrapper .ng-select {
    &.required.ng-invalid.ng-touched {
        .ng-select-container{
            border-color: #dc3545;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
        }
    }
    &.required.ng-invalid.ng-untouched {
        .ng-select-container{
            border-color: #dc3545;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
        }
    }
    &.ng-select-single {
        height: inherit;
        .ng-select-container {
            height: 2.98rem;
            min-height: 2.98rem;
            width: 23em;
        }
    }
}

.update-ip .type-select-wrapper .ng-select {
    margin-bottom: 1.5em;
}

.ip-whitelisting-client-select .ng-select {
    &.ng-select-single {
        .ng-select-container {
            display: inline-flex;
            width: auto;

            background-color: transparent;
            font-size: 2em;
            height: 2em;
            border: none;

            .ng-value-container {
                padding-left: 0;
                font-weight: normal;

                .ng-placeholder {
                    font-weight: normal;
                }
            }
        }

        .ng-select-container:hover {
            box-shadow: none;
            cursor: pointer;
        }

        .ng-value-container {
            margin-right: .5em;
        }

        .ng-arrow {
            width: 10px;
            height: 10px;
            top: -3px;
            border-width: 0 2px 2px 0;
            border-color: black;
            transform: translateY(-5px) rotate(45deg);
        }

        &.ng-select-opened .ng-arrow {
            top: 2px;
            transform: translateY(-5px) rotate(-135deg);
        }

        .ng-dropdown-panel.ng-select-bottom {
            background-color: white;

            box-shadow: -5px 5px 15px #bdbdbd;
            border: 1px solid #e4e4e4;
        }
    }
}
