#application-container {
    font-size: 11px;
    line-height: 1.2;

    & h1, h2, h3, h4, h5, h6 {
        line-height: normal;
    }

    button {
        font-size: inherit;
        line-height: inherit;
    }

    & p, span, label, div.line {
        font-size: 1em;
        line-height: inherit;
    }

    cc-app-launcher > span.hamburger {
        font-size: 24px;
        line-height: 24px;
    }
    div[role="combobox"] {
        font-size: 14.6667px;
        line-height: 17.6px;
    }

    .input-main, .password-change-page input {
        font-size: 13px;

        &.error {
            font-size: 9.451px;
            line-height: 11.3412px;
        }
    }

    .passcode-entry-page input {
        font-size: 13px;

        &.error {
            font-size: 14.6667px;
            line-height: 17.6px;
        }
    }

    .passcode-entry-page cc-button {
        font-size: 14.6667px;
        line-height: 17.6px;
    }
    .login-page cc-button {
        font-size: 12px;
        line-height: 14.95px;
    }

    .password-change-page cc-button {
        font-size: 14.6667px;
        line-height: 17.6px;
    }

    .password-change-page app-password-guidelines cc-expansion-panel .section,
    .password-change-page app-password-guidelines cc-expansion-panel .header,
    .password-change-page app-password-guidelines cc-expansion-panel .line,
    .password-change-page app-password-guidelines cc-expansion-panel .requirement {
        font-size: 11.8182px;
        line-height: 14.1818px;
    }

    .passcode-entry-page app-input .label-text {
        font-size: 14.6667px;
        line-height: 17.6px;
    }

    .password-change-page cc-text-input div.error {
        font-size: 9.451px;
        line-height: 11.3412px;
    }
}
