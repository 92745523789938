@use '/src/assets/styles/variables';

@font-face {
    font-family: "NewsGothic";
    src: url('../fonts/news_gothic_bt_roman.woff') format('woff');
    font-weight: normal;
}

@font-face {
    font-family: "NewsGothic";
    src: url('../fonts/news_gothic_bt_roman_bold.woff') format('woff');
    font-weight: bold;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body {
    font-family: NewsGothic, sans-serif;
    font-size: variables.$font-size-normal;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #000000;
}
