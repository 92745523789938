$tablet: 768px;
$desktop: 992px;
$desktop-lg: 1366px;

$font-size-normal: 11pt;
$font-size-h1: 24pt;

$header-height: 48px;

$header-background-color: #202020;

$hedgeserv-blue: #00AEEF;
$hedgeserv-red: #AF2F33;

$color-disabled: #E6E7E8;
$color-gray: #A7A9AC;
$color-lightgray: #A9A9A9;
$color-yellow: #FDB815;
$color-darkgray: #434447;
